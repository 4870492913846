<template>

  <div>
    <div class="pb-2">
      <b-media vertical-align="center">
        <template #aside>
          <feather-icon
            icon="UsersIcon"
            size="36"
          />
        </template>
        <h1 class="mt-1">
          Identities Portal
        </h1>
      </b-media>
    </div>
    <b-card
      no-body
    >
      <b-card-body class="px-0">
        <div class="mx-2 mb-2">
          <b-row>
            <!-- Selection -->
            <b-col cols="12">
              <label>Quick Filters</label>
              <b-row class="ml-1">
                <b-button
                  v-for="type in ['Power Users (10)', 'Inactive (2)', 'Disabled (1)', 'Deleted (100)', 'Offboarding Candidates (5)', 'Threats Detected (3)', 'Vulenrability Detected (4)']"
                  :key="type"
                  class="mr-1"
                  size="sm"
                >
                  {{ type }}
                </b-button>
              </b-row>
            </b-col>
          </b-row>
        </div>
        <div class="m-2">

          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="2"
              class="mb-1 mb-md-0"
            >
              <label>Entries per page</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector mx-50"
              />
            </b-col>
            <!-- Selection -->
            <b-col cols="6">
              <label>Show Columns</label>
              <v-select
                v-model="selected"
                multiple
                style="width:100%"
                :options="Object.keys(tableColumns)"
                @input="setSelected"
              />
            </b-col>
            <!-- Search -->
            <b-col
              cols="4"
              md="4"
            >
              <label>Quick Search</label>
              <b-form-input
                v-model="searchQuery"
                class="mr-1"
                placeholder="Search..."
              />
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchUsers"
          responsive
          :fields="Object.values(tableColumns)"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #table-colgroup="tableColumns">
            <col
              v-for="field in Object.keys(tableColumns)"
              :key="field"
              :style="{ width: field === 'tags' ? '1200px' : '180px' }"
            >
          </template>
          <!-- Column: User -->
          <template #cell(user)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.avatar"
                  :text="avatarText(data.item.fullName)"
                  :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                  :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
                />
              </template>
              <b-link
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.fullName }}
              </b-link>
              <small class="text-muted">@{{ data.item.username }}</small>
            </b-media>
          </template>

          <!-- Column: Role -->
          <template #cell(roles)="data">
            <div class="text-nowrap">
              <feather-icon
                v-for="role in data.item.roles"
                :key="role"
                :icon="resolveUserRoleIcon(role)"
                size="18"
                class="mr-50"
                :class="`text-${resolveUserRoleVariant(role)}`"
              />
              <!-- <span class="align-text-top text-capitalize">{{ data.item.roles }}</span> -->
            </div>
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
              class="text-capitalize"
            >
              {{ data.item.status }}
            </b-badge>
          </template>
          <template #cell(services)="data">
            <img
              v-for="service in data.item.services"
              :key="service"
              width="32"
              class="mr-1"
              :src="require(`@/assets/images/logos/${service}.webp`)"
              :text="avatarText(data.item.fullName)"
              :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
            >
          </template>

          <template #cell(tags)="data">
            <b-badge
              v-for="tag in data.item.tags"
              :key="tag.name"
              :variant="`light-${tag.color}`"
              class="mr-1"
            >
              {{ tag.name }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>

              <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: data.item.id } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item>
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalUsers"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BCardBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useUsersList from './useUsersList'
import userStoreModule from '../userStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BCardBody,
    // BCardSubTitle,
    // BCardTitle,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  data() {
    return {
      option: [],
      selected: [],
      tableColumns: {
        user: { key: 'user', sortable: true },
        email: { key: 'email', sortable: true },
        roles: { key: 'roles', sortable: true },
        services: {
          key: 'services',
          label: 'Services',
          sortable: true,
        },
        status: { key: 'status', sortable: true },
        createdAt: {
          key: 'createdAt',
          sortable: true,
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        offboardedAt: {
          key: 'offboardedAt',
          sortable: true,
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        deletedAt: {
          key: 'deletedAt',
          sortable: true,
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        tags: { key: 'tags', sortable: true },
        lastActivity: {
          key: 'lastActivity',
          label: 'Last Activity',
          thClass: 'd-none',
          tdClass: 'd-none',
        },
      },
    }
  },
  mounted() {
    this.selected = Object.keys(this.tableColumns)
    const tthis = this
    this.selected = this.selected.filter(col => tthis.tableColumns[col].thClass !== 'd-none')
  },
  methods: {
    setSelected(value) {
      const tthis = this
      Object.keys(this.tableColumns).forEach(col => {
        if (value.includes(col)) {
          tthis.tableColumns[col].thClass = ''
          tthis.tableColumns[col].tdClass = ''
        } else {
          tthis.tableColumns[col].thClass = 'd-none'
          tthis.tableColumns[col].tdClass = 'd-none'
        }
      })
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchUsers,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
